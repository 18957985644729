<template>
    <div>
        <PageTitleHeader :pageAccessor="'sessionHeader'">
            <h1 class="title mg-title-header text-center mg-font-bold pb-10">
                <span v-html="textHeader"></span>
            </h1>
        </PageTitleHeader>
        <div class="session-container">
            <section
                class="carousel-container"
                :class="
                    cssVariables['--session-carousel-bg-color']
                        ? 'bg-session-carousel'
                        : 'text-primary'
                "
            ></section>

            <section class="table-schedule-container">
                <div
                    class="table-schedule-body"
                    :class="{
                        'bg-transparent': Boolean(imagePageHeader)
                    }"
                >
                    <b-tabs
                        class="mg-directory-tabs mg-directory-tabs--live-tab-1 mg-directory-tabs--transparent-tab-2 mg-tabs-no-margin"
                        ref="Tabs"
                        v-model="activeTab"
                        position="is-centered"
                        type="is-boxed"
                        :animated="animated"
                        v-addbuefytabs
                    >
                        <b-tab-item
                            :label="
                                getConfigurableLabel.liveTabLabel
                                    ? getConfigurableLabel.liveTabLabel
                                    : 'Live'
                            "
                        >
                            <div
                                v-if="liveComponentToUse"
                                :class="
                                    cssVariables[
                                        '--session-live-content-bg-color'
                                    ]
                                        ? cssVariables[
                                              '--session-live-content-bg-color'
                                          ]
                                        : 'bg-gradient'
                                "
                            >
                                <component :is="liveComponentToUse" />
                            </div>
                            <div v-else>
                                <!-- table view is default. -->
                                <b-table
                                    class="mg-directory-table table--text-white table-fixed table--cols-wide w-auto"
                                    v-if="liveData"
                                    :ref="'Schedule'"
                                    :striped="striped"
                                    :data="liveData"
                                    :columns="liveColumns"
                                    :stickyHeaders="'false'"
                                >
                                    <b-table-column
                                        v-for="(colInfo, index) in liveColumns"
                                        :field="colInfo.Name"
                                        :key="`${colInfo.Name}-${index}`"
                                        v-slot="props"
                                    >
                                        <a
                                            :href="
                                                '/live-stream/' +
                                                    props.row[
                                                        colInfo.field + '_URL'
                                                    ]
                                            "
                                            >{{ props.row[colInfo.field] }}</a
                                        >
                                    </b-table-column>
                                </b-table>
                            </div>
                        </b-tab-item>

                        <b-tab-item
                            :label="
                                getConfigurableLabel.recordedLabel
                                    ? getConfigurableLabel.recordedLabel
                                    : 'On Demand'
                            "
                        >
                            <div>
                                <prerecorded-listing v-if="1 === activeTab" />
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import {
    initDomObserverForA11yAttrFixes,
    a11yFixBuefyTabsAriaAttrs,
    a11yFixBuefyTabHrefs
} from "@/services/a11y";
import { getModule } from "vuex-module-decorators";
import SessionSchedule from "@/components/schedule/SessionSchedule.vue";
import UpcomingSessionsListing from "@/components/sessions/UpcomingSessionsListing";
import PrerecordedListing from "@/components/sessions/PrerecordedListing.vue";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";

const layoutImagesStore = getModule(layoutImagesVuexModule);

export default {
    name: "SessionsView",
    components: {
        UpcomingSessionsListing,
        SessionSchedule,
        PrerecordedListing,
        PageTitleHeader
    },

    directives: {
        addbuefytabs: {
            bind: function(el, binding, vnode) {
                vnode.context.handleBuefyTabsObserver(el);
            }
        }
    },

    data() {
        return {
            buefyTabsObserver: null,
            defaultSessionHeader: "CURRENT LIVE STREAMS",
            activeCategory: "ALL",
            SessionInfo: null,
            slides: 7,
            value: 0,
            activeTab: 0,
            animated: false,
            Repeat: false,
            Autoplay: false,
            recordedTrackInfo: [],
            words: [
                "J9e7Z_nmark",
                "jQJbteB5Vr0",
                "fatUQpJR3UA",
                "LJVBPkRsuNQ",
                "2xpJaxNPs0Q",
                "T4iC5LaYWeY",
                "rYHDXEmzCaA"
            ],
            liveData: [],
            striped: true,
            liveColumns: [
                { field: "Name", label: "Track", sticky: true },
                { field: "First", label: "8:30am", width: 300 },
                { field: "Second", label: "9:30am", width: 150 },
                { field: "Third", label: "10:30am" },
                { field: "Fourth", label: "11:30am" },
                { field: "Fifth", label: "12:30pm" },
                { field: "Sixth", label: "1:30pm" },
                { field: "Seventh", label: "2:30pm" }
            ],
            // TODO: this needs to come from an API endpoint.
            sessionCategories: ["ALL"],
            newClonedRecordedTrack: [],
            allSessions: []
        };
    },

    computed: {
        imagePageHeader() {
            if (layoutImagesStore.getLayoutImage("sessionHeader")) {
                return layoutImagesStore.getLayoutImage("sessionHeader");
            }
            return layoutImagesStore.getLayoutImage("pagePrimary");
        },

        showActiveCategory() {
            if (this.activeCategory === "ALL") {
                return this.activeCategory;
            }
            const session = this.recordedTrackInfo.find(
                (category) => category.name === this.activeCategory
            );
            return Object.values(session.sessions);
        },

        cssVariables() {
            return this.$store.getters.cssVariables;
        },

        liveContentBgColor() {
            return this.cssVariables["--session-live-content-bg-color"];
        },

        getConfigurableLabel() {
            return this.$store.getters.sessionLabel;
        },

        getLayoutOptions() {
            return this.$store.getters.layoutOptions;
        },

        liveComponentToUse() {
            return this.$store.getters.getPageOptions("sessions").section.live;
        },
        pageheader() {
            return this.$store.getters.getPageOptions("sessions").section
                .pageHeader;
        },
        textHeader() {
            return this.$store.getters.getPageOptions("sessions").textHeader
                ? this.$store.getters.getPageOptions("sessions").textHeader
                : "SCHEDULE";
        }
    },

    watch: {
        activeTab() {
            if (this.getLayoutOptions.usesAlternateSessionRecordedLayout)
                return "";
            this.$nextTick(() => {
                if (1 === this.activeTab) {
                    // this.$router.push("?onDemandOpen");
                    for (let i = 0; i < this.recordedTrackInfo.length; i++) {
                        const which = this.recordedTrackInfo[i].name;
                        this.$refs[which][0].update();
                    }
                } else {
                    this.$router.push("");
                }
            });
        }
    },

    mounted() {
        if (
            this.$route.query &&
            ("onDemandOpen" in this.$route.query ||
                "onDemandFilter" in this.$route.query)
        ) {
            this.activeTab = 1;
        }
    },

    beforeDestroy() {
        if (this.buefyTabsObserver instanceof MutationObserver) {
            this.buefyTabsObserver.disconnect();
        }
    },

    methods: {
        getVideoId(type = null, track = null, index = null) {
            if (!this.SessionInfo || !type || !track || null === index)
                return "";

            if (this.SessionInfo[type]) {
                return this.SessionInfo[type][track].sessions[index]["Vimeo"][
                    "video_id"
                ];
            }

            return "";
        },

        showCategory(cat) {
            this.activeCategory = cat;
        },

        handleBuefyTabsObserver(el) {
            if (this.buefyTabsObserver instanceof MutationObserver) {
                return;
            }
            this.buefyTabsObserver = initDomObserverForA11yAttrFixes(
                el,
                function() {
                    a11yFixBuefyTabsAriaAttrs(el);
                    a11yFixBuefyTabHrefs(el);
                }
            );
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../styles/views/session";
</style>
