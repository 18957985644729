var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageTitleHeader',{attrs:{"pageAccessor":'sessionHeader'}},[_c('h1',{staticClass:"title mg-title-header text-center mg-font-bold pb-10"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.textHeader)}})])]),_c('div',{staticClass:"session-container"},[_c('section',{staticClass:"carousel-container",class:_vm.cssVariables['--session-carousel-bg-color']
                    ? 'bg-session-carousel'
                    : 'text-primary'}),_c('section',{staticClass:"table-schedule-container"},[_c('div',{staticClass:"table-schedule-body",class:{
                    'bg-transparent': Boolean(_vm.imagePageHeader)
                }},[_c('b-tabs',{directives:[{name:"addbuefytabs",rawName:"v-addbuefytabs"}],ref:"Tabs",staticClass:"mg-directory-tabs mg-directory-tabs--live-tab-1 mg-directory-tabs--transparent-tab-2 mg-tabs-no-margin",attrs:{"position":"is-centered","type":"is-boxed","animated":_vm.animated},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":_vm.getConfigurableLabel.liveTabLabel
                                ? _vm.getConfigurableLabel.liveTabLabel
                                : 'Live'}},[(_vm.liveComponentToUse)?_c('div',{class:_vm.cssVariables[
                                    '--session-live-content-bg-color'
                                ]
                                    ? _vm.cssVariables[
                                          '--session-live-content-bg-color'
                                      ]
                                    : 'bg-gradient'},[_c(_vm.liveComponentToUse,{tag:"component"})],1):_c('div',[(_vm.liveData)?_c('b-table',{ref:'Schedule',staticClass:"mg-directory-table table--text-white table-fixed table--cols-wide w-auto",attrs:{"striped":_vm.striped,"data":_vm.liveData,"columns":_vm.liveColumns,"stickyHeaders":'false'}},_vm._l((_vm.liveColumns),function(colInfo,index){return _c('b-table-column',{key:((colInfo.Name) + "-" + index),attrs:{"field":colInfo.Name},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{attrs:{"href":'/live-stream/' +
                                                props.row[
                                                    colInfo.field + '_URL'
                                                ]}},[_vm._v(_vm._s(props.row[colInfo.field]))])]}}],null,true)})}),1):_vm._e()],1)]),_c('b-tab-item',{attrs:{"label":_vm.getConfigurableLabel.recordedLabel
                                ? _vm.getConfigurableLabel.recordedLabel
                                : 'On Demand'}},[_c('div',[(1 === _vm.activeTab)?_c('prerecorded-listing'):_vm._e()],1)])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }