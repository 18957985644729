














































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { SessionDataArray } from "@/types/interfaces";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import Spinners from "@/components/utilities/Spinners.vue";
import UpcomingSessionItem from "@/components/sessions/UpcomingSessionItem.vue";

const sessionStore = getModule(sessionVuexModule);

@Component({
    components: {
        Spinners,
        UpcomingSessionItem
    }
})
export default class SessionSchedule extends Vue {
    isLoading = true;

    created() {
        sessionStore
            .getSessionData({
                tableName: this.$store.getters.awsConfig.sessionTable,
                isDemoData: false
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    get sessionData(): SessionDataArray {
        if (sessionStore.sessionData) {
            return sessionStore.sessionData.upcomingSessions.map((session) => {
                return { ...session, isExpanded: false };
            });
        } else {
            return [];
        }
    }
}
