



















































































































































































































































































































import eventHub from "@/event-hub";

import {
    a11yFixBuefyModalAriaAttrs,
    a11yFixBuefyNotificationAriaAttrs
} from "@/services/a11y";

import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { SessionDataArray, SessionDataObject } from "@/types/interfaces";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import evalsVuexModule from "@/store/vuex-modules/session-evaluations";
import Spinners from "@/components/utilities/Spinners.vue";
import { v4 as uuidv4 } from "uuid";

const sessionStore = getModule(sessionVuexModule);
const evalsStore = getModule(evalsVuexModule);

interface ActiveList {
    id?: number;
    imgPath?: string;
    title?: string;
    speakers?: string;
    company?: string;
    description?: string;
    position?: string;
    startTime?: string;
    minutesBeforeSessionEval?: number;
    disableSessionEval?: boolean;
}

interface CategoryNameMapping {
    param: string;
    displayName: string;
}

@Component({
    components: {
        Spinners
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        },
        addbuefyformfield: {
            bind: function(el) {
                eventHub.$emit("BuefyFormFieldBound", el);
            }
        },
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationBound", el);
            }
        }
    }
})
export default class PrerecordedListing extends Vue {
    // data
    isLoading = false;
    isModalOpen = false;
    activeItemIsSponsor = false;
    activeListId = "0";
    sessionFilter: any = "";
    searchString = "";
    hasViewedEnough = false;
    componentId = uuidv4();

    // getters
    get pageOptions() {
        return this.$store.getters.getPageOptions("sessions") || {};
    }

    get pageOptionsOndemand() {
        return this.$store.getters.getPageOptions("onDemand") || {};
    }

    get showActiveListOnModal(): ActiveList | SessionDataObject | undefined {
        let lookIn = this.sessionData;

        if (this.activeItemIsSponsor) {
            lookIn = this.sponsorVideos;
        }

        const showCase = lookIn.find(
            (item) => item.sessionId === this.activeListId
        );

        return showCase;
    }

    get layoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    get sponsorVideos() {
        return this.$store.getters.sponsorVideos;
    }

    get contentHeading() {
        const returnValue = "On Demand Sessions";
        return returnValue;
    }

    get speakersString() {
        const speakers = this?.showActiveListOnModal?.speakers;
        let returnValue = "";
        if (!speakers || 0 === speakers.length) return returnValue;

        if (Array.isArray(speakers)) {
            returnValue = speakers.map((item) => item.name).join(", ");
        }

        return returnValue;
    }

    get sessionData(): SessionDataArray {
        return sessionStore.sessionOnDemand;
    }

    get allSessionCategories() {
        const categoryList: Array<string> = [];

        this.sessionData.forEach((session) => {
            if (Array.isArray(session.categories)) {
                session.categories.forEach((category: string) => {
                    if (!categoryList.includes(category)) {
                        categoryList.push(category);
                    }
                });
            }
        });

        return categoryList.sort((a, b) => {
            return a.localeCompare(b);
        });
    }

    get filteredSessionsByCategory() {
        const filetredbyCat = this.sessionFilter
            ? this.sessionData
                  .filter(
                      (session) =>
                          session.categories &&
                          session.categories.includes(this.sessionFilter)
                  )
                  .sort((a, b) => a.title.localeCompare(b.title))
            : this.sessionData.sort((a, b) => a.title.localeCompare(b.title));

        if (this.searchString) {
            return this.searchOnDemand(filetredbyCat);
        } else {
            return filetredbyCat;
        }
    }

    get sessionsToUse() {
        return this.useSessionFilter
            ? this.filteredSessionsByCategory
            : this.sessionData;
    }

    get useSessionFilter() {
        return this.pageOptions.onDemandCategoryFilter;
    }

    get categoryNameMappings() {
        return this.pageOptions.onDemandCategoryMappings
            ? this.pageOptions.onDemandCategoryMappings
            : [];
    }

    get activeSessionEvals() {
        return evalsStore.myResponses.filter(
            (resp) => resp.sessionId === this.activeListId
        );
    }

    get evalOptions() {
        return this.$store.getters.getPageOptions("sessionEvals");
    }

    get isEvalEnabled() {
        const isGloballyEnabled =
            this.evalOptions && "enabled" in this.evalOptions
                ? this.evalOptions.enabled
                : true;
        const isSessionEnabled =
            this.showActiveListOnModal &&
            this.showActiveListOnModal.disableSessionEval !== true;
        return isGloballyEnabled && isSessionEnabled;
    }

    // lifecycle
    created() {
        this.isLoading = true;

        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$on(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        evalsStore.getMyResponses();
        sessionStore
            .getSessionData({
                tableName: this.$store.getters.awsConfig.sessionTable,
                isDemoData: false
            })
            .finally(() => {
                this.isLoading = false;
                this.openModalFromParam();
                this.sessionFilterFromUrl();
            });
    }

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$off(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );
    }

    //methods
    resetSearch() {
        this.searchString = "";
        this.sessionFilter = "";
        this.$router.push("/sessions");
    }
    sessionFilterFromUrl() {
        if (this.$route.query.onDemandFilter) {
            return (this.sessionFilter = this.$route.query.onDemandFilter);
        } else {
            this.updateSessionFilter();
        }
    }
    async showModal(id: string, isSponsor: boolean) {
        this.activeListId = id;
        this.activeItemIsSponsor = isSponsor;

        if (this.isEvalEnabled) {
            const hasViewedEnough = await evalsStore.hasViewedEnough({
                sessionId: id,
                startTime: this.showActiveListOnModal
                    ? this.showActiveListOnModal.startTime
                    : "",
                minutesBeforeSessionEval: this.showActiveListOnModal
                    ? this.showActiveListOnModal.minutesBeforeSessionEval
                    : evalsStore.defaultMinutes
            });

            if (hasViewedEnough) {
                this.hasViewedEnough = true;
            }
        }

        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
        this.hasViewedEnough = false;
    }

    openModalFromParam() {
        const sessionId =
            this.$route.query && this.$route.query.onDemandOpen
                ? this.$route.query.onDemandOpen.toString()
                : "";

        if (sessionId) {
            const session = this.sessionData.find(
                (session) => session.sessionId === sessionId
            );

            if (session) {
                this.showModal(sessionId, false);
            }
        }
    }

    @Watch("sessionFilter")
    updateSessionFilter() {
        const query = this.$route.query ? { ...this.$route.query } : {};
        query.onDemandFilter = this.sessionFilter;

        this.$router.push({
            query: query
        });
    }

    // @Watch("searchString")
    searchOnDemand(cb: any) {
        return cb.filter(
            (session: any) =>
                session.title
                    .toLowerCase()
                    .includes(this.searchString.toLowerCase()) ||
                (session.description &&
                    session.description
                        .toLowerCase()
                        .includes(this.searchString.toLowerCase()))
        );
    }

    mappedName(paramName: string) {
        if (!this.categoryNameMappings.length) {
            return paramName;
        }

        const thisItem = this.categoryNameMappings.find(
            (mapping: CategoryNameMapping) => mapping.param === paramName
        );

        return thisItem ? thisItem.displayName : paramName;
    }

    openEvaluation() {
        evalsStore.activateSessionEval({
            sessionId: this.activeListId,
            sessionName:
                this.showActiveListOnModal && this.showActiveListOnModal.title
                    ? this.showActiveListOnModal.title
                    : "our session",
            startTime:
                this.showActiveListOnModal &&
                this.showActiveListOnModal.startTime
                    ? this.showActiveListOnModal.startTime
                    : ""
        });
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }

    handleBuefyNotificationFixes(el: HTMLElement) {
        a11yFixBuefyNotificationAriaAttrs(el);
    }
}
